import { getArgs } from "../utils";
import api from "./api";

const route = "/purchases";

const PurchasesService = {
  getPurchases: (start_date, end_date, filters) =>
    api.get(
      `${route}/admin/all?start_date=${start_date}&end_date=${end_date}&${getArgs(
        filters
      )}`
    ),
  getPurchase: (purchase_id) => api.get(`${route}/${purchase_id}`),
  postPurchase: (purchase) => api.post(route, { ...purchase }),
  putPurchase: (purchase) => api.put(route, { ...purchase }),
  cancelPurchase: (purchase_id, reason) =>
    api.post(`${route}/cancel/${purchase_id}/admin`, {
      reason,
      status: "cancelled",
    }),
  revokePurchase: (purchase_id) =>
    api.post(`${route}/cancel/${purchase_id}/admin`, {
      status: "revoked",
    }),
  editExpiration: (purchase_id, expiration_days) =>
    api.put(`${route}`, { purchase_id, expiration_days }),
  deletePurchase: (purchase_id) => api.delete(`${route}/${purchase_id}`),
};

export default PurchasesService;
