import { navigate } from "@reach/router";
import React, { createContext, useReducer, useContext } from "react";
import CustomerReducer from "../reducers/CustomerReducer";
import CustomerService from "../services/CustomerService";
import {
  SET_CUSTOMER,
  CREATE_CUSTOMER,
  CUSTOMERS_RECIBIDOS,
  SET_PROPIEDAD_CUSTOMER,
  SHOW_SPINNER,
  HIDE_SPINNER,
  LINK_RECIBIDO,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  customers: null,
  customer: null,
  link: null,
};

export const CustomerContext = createContext(initialState);

export const CustomerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CustomerReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  const getCustomersByQuery = (query) => {
    CustomerService.getCustomersByQuery(query).then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getPasswordResetLink = (email) => {
    CustomerService.getPasswordResetLink(email).then((res) => {
      const { link } = res.data;
      dispatch({ type: LINK_RECIBIDO, payload: link });
    });
  };

  const getAllCustomers = (filters) => {
    dispatch({ type: CUSTOMERS_RECIBIDOS, payload: null });
    CustomerService.getAllCustomers(filters).then((res) => {
      const { users } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: users });
    });
  };

  const getCustomerAccesses = (filters) => {
    dispatch({ type: CUSTOMERS_RECIBIDOS, payload: null });
    CustomerService.getCustomerAccesses(filters).then((res) => {
      const { users } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: users });
    });
  };

  const getCustomer = (customer_id) => {
    CustomerService.getCustomer(customer_id).then((res) => {
      const { user } = res.data;
      dispatch({ type: SET_CUSTOMER, payload: user });
    });
  };

  const extenderAcceso = (
    user_id,
    course_id,
    expiration_days,
    is_gift,
    payment_method_id
  ) => {
    CustomerService.extenderAcceso(
      user_id,
      course_id,
      expiration_days,
      is_gift,
      payment_method_id
    ).then(() => {
      success("¡Acceso agregado!");
      getCustomer(user_id);
      hideModal();
    });
  };

  const revokeAccess = (purchase_id, customer_id) => {
    CustomerService.revokeAccess(purchase_id).then((res) => {
      getCustomer(customer_id);
      success("¡Acceso eliminado!");
      hideModal();
    });
  };

  const removeClasses = (customer_id, amount) => {
    CustomerService.removeClasses(customer_id, amount).then(() => {
      getCustomer(customer_id);
      success("¡Clases Restadas!");
      hideModal();
    });
  };

  const clearCustomer = () => {
    dispatch({ type: SET_CUSTOMER, payload: null });
  };

  const setPropiedadCustomer = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_CUSTOMER, payload: { key, value } });
  };

  const createCustomer = () => {
    dispatch({ type: CREATE_CUSTOMER });
  };

  const postCustomer = (customer) => {
    dispatch({ type: SHOW_SPINNER });

    const handleSuccess = ({ data }) => {
      success("Cliente guardado con éxito.");
      dispatch({ type: HIDE_SPINNER });
      if (data.user) {
        return navigate(`/myadmin/customer/${data.user.user_id}`);
      } else if (!isNaN(customer.user_id)) {
        return navigate(`/myadmin/customer/${customer.user_id}`);
      }
      navigate("/myadmin/cust");
    };

    const handleError = (error) => {
      dispatch({ type: HIDE_SPINNER });
      const { response } = error;
      if (response && response !== null) {
        const { data } = response;
        if (data && data !== null) {
          return alert(data.message);
        }
      }
      alert(error);
    };

    if (isNaN(customer.user_id)) {
      CustomerService.postCustomer(customer)
        .then(handleSuccess)
        .catch(handleError);
    } else {
      CustomerService.putCustomer(customer)
        .then(handleSuccess)
        .catch(handleError);
    }
  };

  const deleteCustomer = (customer_id) => {
    CustomerService.deleteCustomer(customer_id).then((res) => {
      navigate("/myadmin/customers");
      success("Cliente eliminado con éxito.");
      hideModal();
    });
  };

  const removeCustomerClasses = (customer_id, amount) => {
    CustomerService.removeClasses(customer_id, amount).then(() => {
      success("Clases eliminadas con éxito.");
      hideModal();
      getCustomer(customer_id);
    });
  };

  const addCustomerClasses = (customer_id, amount, expiration_days) => {
    CustomerService.giveClasses(customer_id, amount, expiration_days).then(
      () => {
        success("Clases agregadas con éxito.");
        hideModal();
        getCustomer(customer_id);
      }
    );
  };

  const clearLink = () => {
    dispatch({ type: LINK_RECIBIDO, payload: null });
  };

  return (
    <CustomerContext.Provider
      value={{
        ...state,
        getCustomer,
        getAllCustomers,
        getCustomersByQuery,
        extenderAcceso,
        clearCustomer,
        revokeAccess,
        removeClasses,
        createCustomer,
        postCustomer,
        deleteCustomer,
        clearLink,
        getCustomerAccesses,
        getPasswordResetLink,
        addCustomerClasses,
        setPropiedadCustomer,
        removeCustomerClasses,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
