import React, { useContext, useEffect } from "react";
import { CoursesContext } from "../../context/CoursesContext";
import { ProductsContext } from "../../context/ProductsContext";
import SingleCourseProductForm from "./SingleCourseProductForm";

const CourseProductForm = () => {
  const { courses, getCourses } = useContext(CoursesContext);
  const { product, setPropertyProduct } = useContext(ProductsContext);

  useEffect(() => {
    getCourses();
  }, []);

  const addCourseProduct = () => {
    let { course_products } = product;
    course_products = [...course_products];
    course_products.push({ course_id: "", expiration_days: "" });
    setPropertyProduct("course_products", course_products);
  };

  const removeCourseProduct = (course_product) => {
    let current = { ...course_product };
    const { course_products } = product;
    let index = course_products.findIndex(
      ({ course_id }) => String(course_id) === String(current.course_id)
    );
    if (index !== -1) {
      course_products.splice(index, 1);
      setPropertyProduct("course_products", course_products);
    }
  };

  const handleChangeCourseProduct = (course_product, key, value) => {
    let current = { ...course_product };
    const { course_products } = product;
    let index = course_products.findIndex(
      ({ course_id }) => String(course_id) === String(current.course_id)
    );
    current[key] = value;
    if (index !== -1) {
      course_products[index] = { ...current };
      setPropertyProduct("course_products", course_products);
    }
  };

  const renderCourses = () => {
    if (Array.isArray(courses) && Array.isArray(product.course_products)) {
      return product.course_products.map((course_product, index) => (
        <SingleCourseProductForm
          modifier={handleChangeCourseProduct}
          handleDelete={removeCourseProduct}
          course_product={course_product}
          product={product}
          courses={courses}
          key={index}
        />
      ));
    }
  };
  return (
    <div className="my-2">
      <h3>Certificaciones</h3>
      {renderCourses()}
      <button
        type="button"
        onClick={addCourseProduct}
        className="btn btn-outline-dark mb-3"
      >
        <i className="fa fa-plus me-2"></i>Certificación
      </button>
    </div>
  );
};

export default CourseProductForm;
