import { Link } from "@reach/router";
import React, { useContext } from "react";
import { AttemptsContext } from "../../context/AttemptsContext";
import { getValue } from "../../utils";

const AttemptRow = ({ attempt, handleCallback }) => {
  const { saveAttempt } = useContext(AttemptsContext);
  const renderName = (attempt) => {
    if (
      (attempt.user.name !== null && attempt.user.name !== "") ||
      (attempt.user.last_name !== null && attempt.user.last_name !== "")
    ) {
      return `${attempt.user.name} ${attempt.user.last_name}`;
    }
    return "N/D";
  };
  return (
    <tr key={attempt.attempt_id} className="border">
      <td>{attempt.attempt_id}</td>
      <td className="small">
        <Link
          to={`/myadmin/customer/${attempt.user_id}/attempts/${attempt.attempt_id}`}
        >
          {attempt.test.name}
        </Link>
      </td>
      <td className="small">
        <Link to={`/myadmin/customer/${attempt.user.user_id}`}>
          <i className="fa fa-eye me-2"></i>
          {renderName(attempt)}
        </Link>
      </td>
      <td>{attempt.score}</td>
      <td>
        {attempt.requested_diploma ? (
          <i className="fa fa-check text-success"></i>
        ) : (
          ""
        )}
      </td>
      <td>
        {attempt.certificate && attempt.certificate !== null && (
          <i className="fa fa-check text-success"></i>
        )}
      </td>
      <td>
        {attempt.tracking_number !== null && (
          <i className="fa fa-check text-success"></i>
        )}
      </td>
      <td>
        {getValue(attempt, "archived", "boolean") ? (
          <button
            onClick={() =>
              saveAttempt({ ...attempt, archived: false }, handleCallback)
            }
            className="btn btn-sm btn-outline-secondary"
          >
            <i className="fa fa-undo"></i>
          </button>
        ) : (
          <button
            onClick={() =>
              saveAttempt({ ...attempt, archived: true }, handleCallback)
            }
            className="btn btn-sm btn-outline-secondary"
          >
            <i className="fa fa-archive"></i>
          </button>
        )}
      </td>
    </tr>
  );
};

export default AttemptRow;
