import React, { useState, useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import { AttemptsContext } from "../../context/AttemptsContext";
import { CoursesContext } from "../../context/CoursesContext";
import AttemptRow from "../../components/attempts/AttemptRow";

const AdminFinalTests = () => {
  const [grade, setGrade] = useState("");
  const [status, setStatus] = useState("pending");
  const [courseId, setCourseId] = useState("");
  const [finished, setFinished] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const { courses, getCourses } = useContext(CoursesContext);
  const { attempts, getFinalExamsAttempts } =
    useContext(AttemptsContext);

  useEffect(() => {
    getCourses();
    fetchAttempts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(attempts) && firstLoad) {
      setFirstLoad(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempts]);

  useEffect(() => {
    if (!firstLoad) {
      fetchAttempts();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grade, status, finished, courseId]);

  const fetchAttempts = () => {
    getFinalExamsAttempts({ course_id: courseId, finished, status, grade });
  };

  const renderOptions = () => {
    if (Array.isArray(courses)) {
      return [
        <option key="" value="">
          Todos los cursos
        </option>,
        ...courses.map((course) => (
          <option key={course.course_id} value={course.course_id}>
            {course.name}
          </option>
        )),
      ];
    }
  };

  const renderAttempts = () => {
    if (Array.isArray(attempts)) {
      if (attempts.length === 0) {
        return <p>No hay exámenes prácticos para mostrar.</p>;
      }
      return attempts.map((attempt) => (
        <AttemptRow
          key={attempt.attempt_id}
          attempt={attempt}
          handleCallback={fetchAttempts}
        />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Examenes Prácticos" />
      <div className="card p-3">
        <div className="row">
          <div className="col-12 col-md-3 mb-2">
            <select
              value={courseId}
              className="form-control"
              onChange={(e) => setCourseId(e.target.value)}
            >
              {renderOptions()}
            </select>
          </div>
          <div className="col-12 col-md-3 mb-2">
            <select
              value={status}
              className="form-control"
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Todos los Exámenes</option>
              <option value="pending">Pendientes</option>
              <option value="finished">Calificados</option>
              <option value="completed">Completados</option>
              <option value="archived">Archivados</option>
            </select>
          </div>
          <div className="col-12 col-md-3 mb-2">
            <button
              className={`btn btn-sm btn-${
                finished ? "primary" : "light"
              } border br-0`}
              onClick={() => setFinished(true)}
            >
              Terminados
            </button>
            <button
              className={`btn btn-sm btn-${
                !finished ? "primary" : "light"
              } border br-0`}
              onClick={() => setFinished(false)}
            >
              Sin Terminar
            </button>
          </div>
          <div className="col-12 col-md-3 mb-2">
            <button
              className={`btn btn-sm btn-${
                grade === "" ? "primary" : "light"
              } border br-0`}
              onClick={() => setGrade("")}
            >
              Todos
            </button>
            <button
              className={`btn btn-sm btn-${
                grade === "approved" ? "primary" : "light"
              } border br-0`}
              onClick={() => setGrade("approved")}
            >
              Aprobados
            </button>
            <button
              className={`btn btn-sm btn-${
                grade === "failed" ? "primary" : "light"
              } border br-0`}
              onClick={() => setGrade("failed")}
            >
              Reprobados
            </button>
          </div>
        </div>
        <div className="table-responsive my-2">
          <table className="table">
            <thead>
              <tr className="bg-light bold p-2 border">
                <td>#ID</td>
                <td>Examen</td>
                <td>Usuario</td>
                <td>Resultado</td>
                <td>Diploma</td>
                <td>Certificado</td>
                <td>Guía</td>
                <td>Acciones</td>
              </tr>
            </thead>
            <tbody>{renderAttempts()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminFinalTests;
