import React, { useContext, useEffect } from "react";
import { CourseAccessContext } from "../../context/CourseAccessContext";
import { CoursesContext } from "../../context/CoursesContext";
import { getValue } from "../../utils";
import moment from "moment";

const CourseAccessForm = ({ purchases, handleCancel, handleCallback }) => {
  const { courses, getCourses } = useContext(CoursesContext);
  const { course_access, saveCourseAccess, setPropertyCourseAccess } =
    useContext(CourseAccessContext);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (course_access && course_access !== null) {
      if (!course_access.final_exam || course_access.final_exam === null) {
        setPropertyCourseAccess("final_exam", "Upper Body");
      }
      if (
        course_access.course_id !== null &&
        course_access.expiration_date === null
      ) {
        const currentCourse = courses.find(
          ({ course_id }) =>
            parseInt(course_access.course_id) === parseInt(course_id)
        );
        if (currentCourse && currentCourse !== null) {
          setPropertyCourseAccess(
            "expiration_date",
            moment()
              .add(currentCourse.default_expiration_days, "days")
              .format("YYYY-MM-DD")
          );
        }
      }
    }
  }, [course_access]);

  useEffect(() => {
    if (Array.isArray(courses) && course_access && course_access !== null) {
      const firstCourse = courses[0];
      if (
        !course_access.course_id ||
        (course_access.course_id === null && firstCourse)
      ) {
        setPropertyCourseAccess("course_id", firstCourse.course_id);
      }
    }
  }, [courses]);

  useEffect(() => {
    if (
      Array.isArray(purchases) &&
      (!course_access.purchase_id || course_access.purchase_id === null)
    ) {
      const firstPurchase = purchases[0];
      if (firstPurchase && firstPurchase !== null) {
        setPropertyCourseAccess("purchase_id", firstPurchase.purchase_id);
      }
    }
  }, [course_access, purchases]);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveCourseAccess(course_access, handleCallback);
  };

  const renderCourses = () => {
    if (Array.isArray(courses)) {
      return courses
        .filter((course) => course.course_id < 4)
        .map((course) => (
          <option key={course.course_id} value={course.course_id}>
            {course.name}
          </option>
        ));
    }
  };

  const renderPurchases = () => {
    if (Array.isArray(purchases)) {
      return purchases.map((purchase) => (
        <option key={purchase.purchase_id} value={purchase.purchase_id}>
          #{purchase.purchase_id} - {purchase.product.name}
        </option>
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <label>Certificación</label>
        <select
          onChange={(e) => setPropertyCourseAccess("course_id", e.target.value)}
          value={getValue(course_access, "course_id")}
          className="form-control mb-3"
        >
          {renderCourses()}
        </select>
        <label>Compra</label>
        <select
          onChange={(e) =>
            setPropertyCourseAccess("purchase_id", e.target.value)
          }
          value={getValue(course_access, "purchase_id")}
          className="form-control mb-3"
        >
          {renderPurchases()}
        </select>
        <label>Fecha de Expiración</label>
        <input
          type="date"
          className="form-control mb-3"
          value={getValue(course_access, "expiration_date", "date")}
          onChange={(e) =>
            setPropertyCourseAccess("expiration_date", e.target.value)
          }
        />
        <label>Examen Final</label>
        <select
          value={getValue(course_access, "final_exam")}
          onChange={(e) =>
            setPropertyCourseAccess("final_exam", e.target.value)
          }
          className="form-control mb-3"
        >
          <option value="Upper Body">Upper Body</option>
          <option value="Lower Body">Lower Body</option>
        </select>
        <div className="row mt-3 align-items-center">
          <div className="col-6">
            <button
              type="button"
              onClick={handleCancel}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button type="submit" className="btn w-100 btn-primary">
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CourseAccessForm;
